// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-cara-pembayaran-js": () => import("./../src/pages/cara-pembayaran.js" /* webpackChunkName: "component---src-pages-cara-pembayaran-js" */),
  "component---src-pages-checkout-js": () => import("./../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-copyright-js": () => import("./../src/pages/copyright.js" /* webpackChunkName: "component---src-pages-copyright-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontak-js": () => import("./../src/pages/kontak.js" /* webpackChunkName: "component---src-pages-kontak-js" */),
  "component---src-pages-produk-js": () => import("./../src/pages/produk.js" /* webpackChunkName: "component---src-pages-produk-js" */),
  "component---src-pages-sukses-js": () => import("./../src/pages/sukses.js" /* webpackChunkName: "component---src-pages-sukses-js" */),
  "component---src-pages-syarat-dan-ketentuan-js": () => import("./../src/pages/syarat-dan-ketentuan.js" /* webpackChunkName: "component---src-pages-syarat-dan-ketentuan-js" */),
  "component---src-templates-blog-details-js": () => import("./../src/templates/blogDetails.js" /* webpackChunkName: "component---src-templates-blog-details-js" */),
  "component---src-templates-details-js": () => import("./../src/templates/details.js" /* webpackChunkName: "component---src-templates-details-js" */),
  "component---src-templates-product-categories-js": () => import("./../src/templates/productCategories.js" /* webpackChunkName: "component---src-templates-product-categories-js" */)
}

